import React from 'react'
import { useState } from 'react';
import {AiOutlineClose, AiOutlineMenu,AiFillTag,AiOutlineLogout} from 'react-icons/ai';
import {FaHome,FaCalendar,FaShoppingBag} from 'react-icons/fa';
import { Link } from "react-router-dom";

export const Navbar = () => {
    //sm navbar state 
    const [nav,setNav] = useState(false)
    const [prodNav,setProdNav] = useState(false)
    const [aiNav,setAINav] = useState(false)
    const [solutionsNav,setSolutionsNav] = useState(false)
    const [resourcesNav,setResourcesNav] = useState(false)
    const [PartnersNav,setPartnersNav] = useState(false)

  return (
    <div className='bg-precision sticky top-0 w-[100%] flex md:justify-evenly items-center p-2 mb-4 justify-between'>
        <div className='text-sm  text-white font-bold md:text-2xl w-[40%] text-left font-mono'>Celanis</div>
        {/*for large screen devices*/}
        <div className='font-mono hidden md:flex text-white w-[60%] justify-between items-center'>
            <Link className='relative' onMouseOver={(e)=>setProdNav(true)}  onMouseLeave={(e)=>setProdNav(false)}>Cloud
                <div className={prodNav?'absolute inset-0 w-50 top-6':'hidden'} >
                    <ul className=' w-60 text-center bg-precision'>
                        <li className='border-1 cursor-pointer  hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Overview Summary</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >System Integration</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Scale</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`}>Container security</Link>
                        </li>
                    </ul>
                </div>
            </Link>
            <Link className='relative' onMouseOver={(e)=>setProdNav(true)}  onMouseLeave={(e)=>setAINav(false)}>AI
                <div className={aiNav?'absolute inset-0 w-50 top-6':'hidden'} >
                    <ul className=' w-60 text-center bg-precision'>
                        <li className='border-1 cursor-pointer  hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Overview Summary</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >System Integration</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Scale</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`}>Container security</Link>
                        </li>
                    </ul>
                </div>
            </Link>
            <Link className='relative' onMouseOver={(e)=>setSolutionsNav(true)}  onMouseLeave={(e)=>setSolutionsNav(false)}>Solutions
                <div className={solutionsNav?'absolute inset-0 w-50 top-6':'hidden'} >
                    <ul className=' w-60 text-center bg-precision'>
                        <li className='border-1 cursor-pointer  hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Overview Summary</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >System Integration</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Scale</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`}>Container security</Link>
                        </li>
                    </ul>
                </div>
            </Link>
            <Link className='relative' onMouseOver={(e)=>setResourcesNav(true)}  onMouseLeave={(e)=>setResourcesNav(false)}>Resources
                <div className={resourcesNav?'absolute inset-0 w-50 top-6':'hidden'} >
                    <ul className=' w-60 text-center bg-precision'>
                        <li className='border-1 cursor-pointer  hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Overview Summary</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >System Integration</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Scale</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`}>Container security</Link>
                        </li>
                    </ul>
                </div>
            </Link>
            <Link className='relative' onMouseOver={(e)=>setPartnersNav(true)}  onMouseLeave={(e)=>setPartnersNav(false)}>Partners
                <div className={PartnersNav?'absolute inset-0 w-50 top-6':'hidden'} >
                    <ul className=' w-60 text-center bg-precision'>
                        <li className='border-1 cursor-pointer  hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Overview Summary</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >System Integration</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`} >Scale</Link>
                        </li>
                        <li className='border-1 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                        <Link to={`/`}>Container security</Link>
                        </li>
                    </ul>
                </div>
            </Link>
            <Link class="text-md rounded-md border cursor-pointer text-white border-slate-300 bg-transparent px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700 dark:text-emerald-400 dark:hover:border-emerald-400">Contact us</Link>
        </div>
        {/*for small screen devices*/}
        <div className='md:hidden cursor-pointer text-white'>
            <AiOutlineMenu size={20} onClick={()=>{setNav(!nav)}}/>
        </div>
        <div className={nav?'fixed top-0 left-0 w-[60%] flex flex-col z-30 sm:hidden bg-precision bg-opacity-75 ': 'hidden'}>
            <nav className='duration-300'> 
            <ul className='flex flex-col p-4 text-white bg-black bg-opacity-25'>
                    <li className='text-m py-2 flex items-center border-b-slate-50'>
                        <Link to={`/home`} className='p-1 flex items-center justify-between cursor-pointer'> 
                            Cloud
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/booking`} className='p-1 flex items-center justify-between cursor-pointer'>
                            AI
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                            Solutions
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                        Resources
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                        Partners
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                        Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    
  )
}
